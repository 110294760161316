import React, { useState, useEffect } from "react";
import LogoCNC from "../../images/logo-cnc.png";
import LogoCorferias from "../../images/logo-corferias.png";
import ImgVisor1Corferias from "../../images/visor1-corferias.png";
import ImgVisor2Corferias from "../../images/visor2-corferias.png";
import ImgVisor3Corferias from "../../images/visor3-corferias.png";
import ImgVisor4Corferias from "../../images/visor4-corferias.png";
import ImgVisor5Corferias from "../../images/visor5-corferias.png";
import ImgVisor6Corferias from "../../images/visor6-corferias.png";
import ImgVisor7Corferias from "../../images/visor7-corferias.png";
import ImgVisor8Corferias from "../../images/visor8-corferias.png";
import ImgVisor9Corferias from "../../images/visor9-corferias.png";
import ImgVisor10Corferias from "../../images/visor10-corferias.png";
import ImgVisor11Corferias from "../../images/visor11-corferias.png";
import ImgVisor12Corferias from "../../images/visor12-corferias.png";
import ImgVisor13Corferias from "../../images/visor13-corferias.png";
import ImgVisor14Corferias from "../../images/visor14-corferias.png";
import ImgVisor15Corferias from "../../images/visor15-corferias.png";
import ImgVisor16Corferias from "../../images/visor16-corferias.png";
import ImgVisor17Corferias from "../../images/visor17-corferias.png";
import ImgVisor18Corferias from "../../images/visor18-corferias.png";
import ImgVisor19Corferias from "../../images/visor19-corferias.png";
import ImgVisor20Corferias from "../../images/visor20-corferias.png";
import ImgVisor21Corferias from "../../images/visor21-corferias.png";
import ImgVisor22Corferias from "../../images/visor22-corferias.png";
import ImgVisor23Corferias from "../../images/visor23-corferias.png";
import ImgVisor24Corferias from "../../images/visor24-corferias.png";
import ImgVisor25Corferias from "../../images/visor25-corferias.png";
import ImgVisor26Corferias from "../../images/visor26-corferias.png";
import ImgVisor27Corferias from "../../images/visor27-corferias.png";
import ImgVisor28Corferias from "../../images/visor28-corferias.png";
import ImgVisor29Corferias from "../../images/visor29-corferias.png";
import ImgVisor30Corferias from "../../images/visor30-corferias.png";
import ImgVisor31Corferias from "../../images/visor31-corferias.png";
import ImgVisor32Corferias from "../../images/visor32-corferias.png";
import ImgVisor33Corferias from "../../images/visor33-corferias.png";
import ImgVisor34Corferias from "../../images/visor34-corferias.png";
import ImgVisor35Corferias from "../../images/visor35-corferias.png";
import ImgVisor36Corferias from "../../images/visor36-corferias.png";
import ImgVisor37Corferias from "../../images/visor37-corferias.png";
import ImgVisor38Corferias from "../../images/visor38-corferias.png";
import ImgVisor39Corferias from "../../images/visor39-corferias.png";
import ImgVisor40Corferias from "../../images/visor40-corferias.png";
import ImgVisor41Corferias from "../../images/visor41-corferias.png";
import ImgVisor42Corferias from "../../images/visor42-corferias.png";
import ImgVisor43Corferias from "../../images/visor43-corferias.png";
import ImgVisor44Corferias from "../../images/visor44-corferias.png";
import ImgVisor45Corferias from "../../images/visor45-corferias.png";
import ImgVisor46Corferias from "../../images/visor46-corferias.png";
import ImgVisor47Corferias from "../../images/visor47-corferias.png";
import ImgVisor48Corferias from "../../images/visor48-corferias.png";
import ImgVisor49Corferias from "../../images/visor49-corferias.png";
import ImgVisor50Corferias from "../../images/visor50-corferias.png";
import ImgVisor51Corferias from "../../images/visor51-corferias.png";
import ImgVisor52Corferias from "../../images/visor52-corferias.png";
import ImgVisor53Corferias from "../../images/visor53-corferias.png";
import ImgVisor54Corferias from "../../images/visor54-corferias.png";
import ImgVisor55Corferias from "../../images/visor55-corferias.png";
import ImgVisor56Corferias from "../../images/visor56-corferias.png";
import ImgVisor57Corferias from "../../images/visor57-corferias.png";
import ImgVisor58Corferias from "../../images/visor58-corferias.png";
import ImgVisor59Corferias from "../../images/visor59-corferias.png";
import ImgVisor60Corferias from "../../images/visor60-corferias.png";
import ImgVisor61Corferias from "../../images/visor61-corferias.png";
import ImgVisor62Corferias from "../../images/visor62-corferias.png";
import ImgVisor63Corferias from "../../images/visor63-corferias.png";
import ImgVisor64Corferias from "../../images/visor64-corferias.png";
import ImgVisor65Corferias from "../../images/visor65-corferias.png";
import ImgVisor66Corferias from "../../images/visor66-corferias.png";
import ImgVisor67Corferias from "../../images/visor67-corferias.png";
import ImgVisor68Corferias from "../../images/visor68-corferias.png";
import ImgVisor69Corferias from "../../images/visor69-corferias.png";
import ImgVisor70Corferias from "../../images/visor70-corferias.png";
import ImgVisor71Corferias from "../../images/visor71-corferias.png";
import ImgVisor72Corferias from "../../images/visor72-corferias.png";
import ImgVisor73Corferias from "../../images/visor73-corferias.png";
import ImgVisor74Corferias from "../../images/visor74-corferias.png";
import ImgVisor75Corferias from "../../images/visor75-corferias.png";
import ImgVisor76Corferias from "../../images/visor76-corferias.png";
import ImgVisor77Corferias from "../../images/visor77-corferias.png";
import ImgVisor78Corferias from "../../images/visor78-corferias.png";
import ImgVisor79Corferias from "../../images/visor79-corferias.png";
import ImgVisor80Corferias from "../../images/visor80-corferias.png";
import ImgVisor81Corferias from "../../images/visor81-corferias.png";

import { Redirect, Route, Link, useHistory } from "react-router-dom";
import { createTheme, responsiveFontSizes, ThemeProvider } from "@mui/material/styles";
import { AppBar, Button, Card, CardHeader, CardActions, CardContent, Container, Divider, Grid, Toolbar, Typography, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import LogoutIcon from '@mui/icons-material/Logout';

import Sesion from "../Sesion";
import Visor1Corferias from "./Visor1Corferias";
import Visor2Corferias from "./Visor2Corferias";
import Visor3Corferias from "./Visor3Corferias";
import Visor4Corferias from "./Visor4Corferias";
import Visor5Corferias from "./Visor5Corferias";
import Visor6Corferias from "./Visor6Corferias";
import Visor7Corferias from "./Visor7Corferias";
import Visor8Corferias from "./Visor8Corferias";
import Visor9Corferias from "./Visor9Corferias";
import Visor10Corferias from "./Visor10Corferias";
import Visor11Corferias from "./Visor11Corferias";
import Visor12Corferias from "./Visor12Corferias";
import Visor13Corferias from "./Visor13Corferias";
import Visor14Corferias from "./Visor14Corferias";
import Visor15Corferias from "./Visor15Corferias";
import Visor16Corferias from "./Visor16Corferias";
import Visor17Corferias from "./Visor17Corferias";
import Visor18Corferias from "./Visor18Corferias";
import Visor19Corferias from "./Visor19Corferias";
import Visor20Corferias from "./Visor20Corferias";
import Visor21Corferias from "./Visor21Corferias";
import Visor22Corferias from "./Visor22Corferias";
import Visor23Corferias from "./Visor23Corferias";
import Visor24Corferias from "./Visor24Corferias";
import Visor25Corferias from "./Visor25Corferias";
import Visor26Corferias from "./Visor26Corferias";
import Visor27Corferias from "./Visor27Corferias";
import Visor28Corferias from "./Visor28Corferias";
import Visor29Corferias from "./Visor29Corferias";
import Visor30Corferias from "./Visor30Corferias";
import Visor31Corferias from "./Visor31Corferias";
import Visor32Corferias from "./Visor32Corferias";
import Visor33Corferias from "./Visor33Corferias";
import Visor34Corferias from "./Visor34Corferias";
import Visor35Corferias from "./Visor35Corferias";
import Visor36Corferias from "./Visor36Corferias";
import Visor37Corferias from "./Visor37Corferias";
import Visor38Corferias from "./Visor38Corferias";
import Visor39Corferias from "./Visor39Corferias";
import Visor40Corferias from "./Visor40Corferias";
import Visor41Corferias from "./Visor41Corferias";
import Visor42Corferias from "./Visor42Corferias";
import Visor43Corferias from "./Visor43Corferias";
import Visor44Corferias from "./Visor44Corferias";
import Visor45Corferias from "./Visor45Corferias";
import Visor46Corferias from "./Visor46Corferias";
import Visor47Corferias from "./Visor47Corferias";
import Visor48Corferias from "./Visor48Corferias";
import Visor49Corferias from "./Visor49Corferias";
import Visor50Corferias from "./Visor50Corferias";
import Visor51Corferias from "./Visor51Corferias";
import Visor52Corferias from "./Visor52Corferias";
import Visor53Corferias from "./Visor53Corferias";
import Visor54Corferias from "./Visor54Corferias";
import Visor55Corferias from "./Visor55Corferias";
import Visor56Corferias from "./Visor56Corferias";
import Visor57Corferias from "./Visor57Corferias";
import Visor58Corferias from "./Visor58Corferias";
import Visor59Corferias from "./Visor59Corferias";
import Visor60Corferias from "./Visor60Corferias";
import Visor61Corferias from "./Visor61Corferias";
import Visor62Corferias from "./Visor62Corferias";
import Visor63Corferias from "./Visor63Corferias";
import Visor64Corferias from "./Visor64Corferias";
import Visor65Corferias from "./Visor65Corferias";
import Visor66Corferias from "./Visor66Corferias";
import Visor67Corferias from "./Visor67Corferias";
import Visor68Corferias from "./Visor68Corferias";
import Visor69Corferias from "./Visor69Corferias";
import Visor70Corferias from "./Visor70Corferias";
import Visor71Corferias from "./Visor71Corferias";
import Visor72Corferias from "./Visor72Corferias";
import Visor73Corferias from "./Visor73Corferias";
import Visor74Corferias from "./Visor74Corferias";
import Visor75Corferias from "./Visor75Corferias";
import Visor76Corferias from "./Visor76Corferias";
import Visor77Corferias from "./Visor77Corferias";
import Visor78Corferias from "./Visor78Corferias";
import Visor79Corferias from "./Visor79Corferias";
import Visor80Corferias from "./Visor80Corferias";
import Visor81Corferias from "./Visor81Corferias";

const Visor1UUID = "035db7c3-8a27-4273-afda-2c9a75188c64"
const Visor2UUID = "30bb7785-a6a5-4a02-8e1c-e5a9f3056f0d"
const Visor3UUID = "65a5a832-d49e-4f8e-8a34-7edd32798cc9"
const Visor4UUID = "9a8f4965-15fe-4100-a7f1-a39878703ff0"
const Visor5UUID = "023ba643-f2e6-48f7-aecf-7bf82691c579"
const Visor6UUID = "6d8d7da5-b4da-42f2-8615-80cafdc6a338"
const Visor7UUID = "1150bea6-7041-4ec1-b9f9-591d8edb5821"
const Visor8UUID = "15ff9639-4208-4302-918c-fcfe3b1e4d83"
const Visor9UUID = "0ce99585-01a1-4c7d-a231-2cadefd93c09"
const Visor10UUID = "38a7cc48-e284-4bcb-af67-99169c694572"
const Visor11UUID = "36190d8d-db7a-4eb4-83ca-fcccb5b9ade7"
const Visor12UUID = "dac3b466-7356-45b3-b635-09143a0b3c7f"
const Visor13UUID = "df555ba3-2eb1-4078-bc3a-cba3c2f82bf3"
const Visor14UUID = "9794fb12-2237-11ed-861d-0242ac120002"
const Visor15UUID = "7f2c2d10-2238-11ed-861d-0242ac120002"
const Visor16UUID = "2c6706ee-2239-11ed-861d-0242ac120002"
const Visor17UUID = "123157ec-223a-11ed-861d-0242ac120002"
const Visor18UUID = "503744e2-223b-11ed-861d-0242ac120002"
const Visor19UUID = "23b9e360-223c-11ed-861d-0242ac120002"
const Visor20UUID = "528d53c6-958e-4a8f-8108-c7d2186ccfc1"
const Visor21UUID = "01f91a22-3e98-49a4-8985-df2239310f4c"
const Visor22UUID = "55825215-eda1-49e6-a54c-98669f1c3807"
const Visor23UUID = "68a7c31b-3aa1-4308-998e-8611c3969062"
const Visor24UUID = "4672b844-8767-4365-881f-ee35a09e9e25"
const Visor25UUID = "71a50610-2727-44d9-8f2a-a8a60819916b"
const Visor26UUID = "89fbf966-8458-44fb-ad8b-15bf48fd7457"
const Visor27UUID = "6bdaf0cc-249a-4c33-aba5-e1bed416b868"
const Visor28UUID = "5e3f9b1c-1496-447a-9845-0ba4fb1fccb1"
const Visor29UUID = "081ad69d-ea63-4980-914c-515aa8a2e7d6"
const Visor30UUID = "f044c54d-cd63-4d41-baab-70e8008077e0"
const Visor31UUID = "ee9585a8-ccb9-4b86-8f4b-96904c85162a"
const Visor32UUID = "88a9ed5f-66de-4908-acf8-26e5a0d0bc35"
const Visor33UUID = "8a8e86e4-b610-4ca8-889c-f67e137046a8"
const Visor34UUID = "1df41e4d-4966-46ce-bcf4-e57ae3eb82bb"
const Visor35UUID = "d09433da-7e94-49f1-b280-f476c1847fbe"
const Visor36UUID = "b87c6663-0770-4dc5-bf08-f2fa14141df8"
const Visor37UUID = "a4bd0819-291a-49dc-825c-ea3f3475fb34"
const Visor38UUID = "ded45783-fbba-4166-a8fb-060112dde7c9"
const Visor39UUID = "282d7255-4437-44f3-a73a-814136ab525c"
const Visor40UUID = "a24b752a-6018-47a0-996a-c96312a082f7"
const Visor41UUID = "3921cdd8-90c0-403e-9531-c519d006e258"
const Visor42UUID = "c5f5f2cb-4d3e-4a01-832e-19cdccb78e79"
const Visor43UUID = "64284253-40da-428e-b4cb-abfdc8ccb3bf"
const Visor44UUID = "d185edda-3611-4d51-a380-e287df821da7"
const Visor45UUID = "40372a17-b529-4e41-ab81-bd26885c6ea4"
const Visor46UUID = "bbff77d2-c903-485f-be98-6fc67a4bdbe4"
const Visor47UUID = "82e9369e-b43a-4db9-9333-7c914466aacc"
const Visor48UUID = "fe5afee4-d54e-4281-b2cd-f3947750887f"
const Visor49UUID = "b735edd6-8db8-4867-9931-0df33db24c23"
const Visor50UUID = "996b0e76-1f17-41dc-a538-56b4f4aa1425"
const Visor51UUID = "2303ad78-708f-4bf3-a1a6-89259a3ef46a"
const Visor52UUID = "bf153a6f-c713-4723-b0d6-3d9771a775cf"
const Visor53UUID = "80ef9f85-ceca-404d-9930-93d2684b8506"
const Visor54UUID = "cf29c052-e12e-4d5e-928f-e4072d4a570a"
const Visor55UUID = "76ec3b99-873b-471a-b7ac-2edd3b83bed2"
const Visor56UUID = "847431bd-6330-44c3-8591-620c8adc84f1"
const Visor57UUID = "927d1cae-84f2-46cd-9acc-128c4199a1e3"
const Visor58UUID = "b7f9c9c8-d032-452e-ad4f-ae6f57242f0d"
const Visor59UUID = "fbccd85e-7816-49e2-b5a5-a09a7c7f7767"
const Visor60UUID = "b28456ee-0b1b-47e2-aad6-e4312e6b1284"
const Visor61UUID = "b2eea7cd-abb5-4b67-9b8d-3d9493a843ad"
const Visor62UUID = "848b3a72-4328-4a90-ba9d-9afc4ceb34b8"
const Visor63UUID = "e853ee2e-80b4-4af9-b503-57a65b2d58f2"
const Visor64UUID = "d17bed08-12a9-4b98-9167-7d3def351cb9"
const Visor65UUID = "f6c02b58-bc7a-46fd-b48a-74ffe42544be"
const Visor66UUID = "904f2d02-fdc5-408a-8cd2-edc321004e44"
const Visor67UUID = "966ec2c4-06d0-4092-8c58-6d366cc9cc03"
const Visor68UUID = "20373146-c01b-4947-9831-354c3d97359b"
const Visor69UUID = "3a616567-f6ef-48ce-82e5-a8adb27a6187"
const Visor70UUID = "04b04120-0789-4cff-a6c4-28a22cb5d60f"
const Visor71UUID = "7bba3fab-1c46-496c-a8a8-e8d5c2ecbb70"
const Visor72UUID = "3d5b24be-7fbf-41af-93c0-cbd4b0b92d82"
const Visor73UUID = "ae9a6e52-231a-49ea-8be4-091cba9bfe3e"
const Visor74UUID = "febe21fb-b031-450f-861d-3710e83a1618"
const Visor75UUID = "ffd55e61-b2d1-4fad-80a3-1eaa91f85dc5"
const Visor76UUID = "eb02dbb1-91eb-4d4c-b942-cc76a7333ede"
const Visor77UUID = "e9550929-975f-4710-a329-ac04dd4ce46c"
const Visor78UUID = "c909887c-935e-441a-87e7-76b1be5ad901"
const Visor79UUID = "fa27eb6a-b307-4565-a524-c705f8a69546"
const Visor80UUID = "8cb2ec91-be0c-472f-a9c8-de729ca54972"
const Visor81UUID = "7dceb845-2d66-48ae-954a-9d9b69b130cc"


const buttonColor = (color) => createTheme({
    palette: {
        primary: {
            main: color
        }
    },
    typography: {
        fontFamily: 'Roboto'
    }
});

const useStyles = makeStyles((theme) => ({
    logo: {
        width: 110,
        [theme.breakpoints.up("sm")]: {
            width: 135
        }
    },
    logo2: {
        width: 110,
        [theme.breakpoints.up("sm")]: {
            width: 125
        }
    },
    logoSpacing: {
        marginRight: theme.spacing(1.5),
        [theme.breakpoints.up("sm")]: {
            marginRight: theme.spacing(2)
        },
        display: "flex"
    },
    logoSpacing2: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
            marginRight: theme.spacing(4)
        },
        display: "flex"
    },
    username: {
        display: 'none',
        [theme.breakpoints.up("md")]: {
            display: 'unset'
        }
    }
}));

export default function Corferias(props) {
    const { theme, API_DEFAULT, setShowBackdrop, setMessage, setSeverity, setSnack, clientUUID, setMLCopyright } = props;
    const actualTheme = createTheme(theme, {
        palette: {
            primary: {
                main: "#CF152D"
            }
        }
    });

    const classes = useStyles();
    const history = useHistory();
    const [pathname, setPathname] = useState(window.location.pathname);
    const pathsVisores = [`/client/${clientUUID}/visual/${Visor1UUID}`, `/client/${clientUUID}/visual/${Visor2UUID}`, `/client/${clientUUID}/visual/${Visor3UUID}`, `/client/${clientUUID}/visual/${Visor4UUID}`, `/client/${clientUUID}/visual/${Visor5UUID}`, `/client/${clientUUID}/visual/${Visor6UUID}`, `/client/${clientUUID}/visual/${Visor7UUID}`,
    `/client/${clientUUID}/visual/${Visor8UUID}`, `/client/${clientUUID}/visual/${Visor9UUID}`, `/client/${clientUUID}/visual/${Visor10UUID}`, `/client/${clientUUID}/visual/${Visor11UUID}`, `/client/${clientUUID}/visual/${Visor12UUID}`, `/client/${clientUUID}/visual/${Visor13UUID}`, `/client/${clientUUID}/visual/${Visor14UUID}`, `/client/${clientUUID}/visual/${Visor15UUID}`,
    `/client/${clientUUID}/visual/${Visor16UUID}`, `/client/${clientUUID}/visual/${Visor17UUID}`, `/client/${clientUUID}/visual/${Visor18UUID}`, `/client/${clientUUID}/visual/${Visor19UUID}`, `/client/${clientUUID}/visual/${Visor20UUID}`, `/client/${clientUUID}/visual/${Visor21UUID}`, `/client/${clientUUID}/visual/${Visor22UUID}`,
    `/client/${clientUUID}/visual/${Visor23UUID}`, `/client/${clientUUID}/visual/${Visor24UUID}`, `/client/${clientUUID}/visual/${Visor25UUID}`, `/client/${clientUUID}/visual/${Visor26UUID}`, `/client/${clientUUID}/visual/${Visor27UUID}`, `/client/${clientUUID}/visual/${Visor28UUID}`,
    `/client/${clientUUID}/visual/${Visor29UUID}`, `/client/${clientUUID}/visual/${Visor30UUID}`, `/client/${clientUUID}/visual/${Visor31UUID}`, `/client/${clientUUID}/visual/${Visor32UUID}`, `/client/${clientUUID}/visual/${Visor33UUID}`, `/client/${clientUUID}/visual/${Visor34UUID}`,
    `/client/${clientUUID}/visual/${Visor35UUID}`, `/client/${clientUUID}/visual/${Visor36UUID}`, `/client/${clientUUID}/visual/${Visor37UUID}`, `/client/${clientUUID}/visual/${Visor38UUID}`, `/client/${clientUUID}/visual/${Visor39UUID}`, `/client/${clientUUID}/visual/${Visor40UUID}`,
    `/client/${clientUUID}/visual/${Visor41UUID}`, `/client/${clientUUID}/visual/${Visor42UUID}`, `/client/${clientUUID}/visual/${Visor43UUID}`, `/client/${clientUUID}/visual/${Visor44UUID}`, `/client/${clientUUID}/visual/${Visor45UUID}`, `/client/${clientUUID}/visual/${Visor46UUID}`,
    `/client/${clientUUID}/visual/${Visor47UUID}`, `/client/${clientUUID}/visual/${Visor48UUID}`, `/client/${clientUUID}/visual/${Visor49UUID}`, `/client/${clientUUID}/visual/${Visor50UUID}`, `/client/${clientUUID}/visual/${Visor51UUID}`, `/client/${clientUUID}/visual/${Visor52UUID}`,
    `/client/${clientUUID}/visual/${Visor53UUID}`, `/client/${clientUUID}/visual/${Visor54UUID}`, `/client/${clientUUID}/visual/${Visor55UUID}`, `/client/${clientUUID}/visual/${Visor56UUID}`, `/client/${clientUUID}/visual/${Visor57UUID}`, `/client/${clientUUID}/visual/${Visor58UUID}`,
    `/client/${clientUUID}/visual/${Visor59UUID}`, `/client/${clientUUID}/visual/${Visor60UUID}`, `/client/${clientUUID}/visual/${Visor61UUID}`, `/client/${clientUUID}/visual/${Visor62UUID}`, `/client/${clientUUID}/visual/${Visor63UUID}`, `/client/${clientUUID}/visual/${Visor64UUID}`,
    `/client/${clientUUID}/visual/${Visor65UUID}`, `/client/${clientUUID}/visual/${Visor66UUID}`, `/client/${clientUUID}/visual/${Visor67UUID}`, `/client/${clientUUID}/visual/${Visor68UUID}`, `/client/${clientUUID}/visual/${Visor69UUID}`, `/client/${clientUUID}/visual/${Visor70UUID}`,
    `/client/${clientUUID}/visual/${Visor71UUID}`, `/client/${clientUUID}/visual/${Visor72UUID}`, `/client/${clientUUID}/visual/${Visor73UUID}`, `/client/${clientUUID}/visual/${Visor74UUID}`, `/client/${clientUUID}/visual/${Visor75UUID}`, `/client/${clientUUID}/visual/${Visor76UUID}`,
    `/client/${clientUUID}/visual/${Visor77UUID}`, `/client/${clientUUID}/visual/${Visor78UUID}`,`/client/${clientUUID}/visual/${Visor79UUID}`,`/client/${clientUUID}/visual/${Visor80UUID}`,`/client/${clientUUID}/visual/${Visor81UUID}`]

    const [sessionErrors, setSessionErrors] = useState(Array(2).fill(false));

    const [auth, setAuth] = useState(localStorage.getItem('auth-corferias'));
    const [accessToken, setAccessToken] = useState(localStorage.getItem('token-corferias'));
    const [username, setUsername] = useState(localStorage.getItem('username-corferias'));

    const validateSession = (username, password) => {
        let errorSesion = false;
        if (username === '') {
            errorSesion = true;
            sessionErrors[0] = true;
        }
        if (password === '') {
            errorSesion = true;
            sessionErrors[1] = true;
        }
        if (errorSesion) {
            setSessionErrors([...sessionErrors]);
            setMessage('Existen campos sin diligenciar o con algún error.');
            setSeverity('error');
            setTimeout(() => { setSnack(true) }, 0);
        }
        else {
            logIn(username, password);
        }
    }

    async function logIn(username, password) {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/users/auth`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                'username': username,
                'password': password
            })
        })
        res
            .json()
            .then(d => {
                setShowBackdrop(false);
                if (d['token']) {
                    rol(d['token'], username);
                }
                else {
                    setMessage('Los datos de usuario y contraseña son incorrectos.');
                    setSeverity('error');
                    setTimeout(() => { setSnack(true) }, 0);
                }
            })
    }

    async function rol(token, usuario) {
        const res = await fetch(`${API_DEFAULT}/users/roles`, {
            headers: { 'Authorization': `Token ${token}` }
        })
        res
            .json()
            .then(d => {
                if (d['roles'].includes('Administrador') || d['roles'].includes('Visor Corferias')) {
                    localStorage.setItem('token-corferias', token);
                    localStorage.setItem('auth-corferias', true);
                    localStorage.setItem('username-corferias', usuario);
                    setUsername(usuario);
                    setAccessToken(token);
                    setAuth(true);
                }
                else {
                    setMessage('El usuario no está habilitado para usar esta aplicación.');
                    setSeverity('warning');
                    setTimeout(() => { setSnack(true) }, 0);
                }
            })
    }

    const appBarCustom = (
        <AppBar position="static" style={{ marginBottom: theme.spacing(2) }}>
            <Toolbar>
                <div className={classes.logoSpacing}>
                    <a style={{ display: "contents" }} href={"https://www.centronacionaldeconsultoria.com/"}>
                        <img src={LogoCNC} alt="logo-cnc" className={classes.logo} />
                    </a>
                </div>
                <div className={classes.logoSpacing2}>
                    <a style={{ display: "contents" }} href={"https://corferias.com/"}>
                        <img src={LogoCorferias} alt="logo-corferias" className={classes.logo2} />
                    </a>
                </div>
                <Typography variant="h5" style={{ flexGrow: 1, fontWeight: 500 }}>
                    Corferias
                </Typography>
                {auth ?
                    <React.Fragment>
                        <Typography className={classes.username} style={{ fontSize: 14, fontWeight: 500, marginRight: theme.spacing(2), fontStyle: "italic" }} variant='body1'>@{username.toLowerCase()}</Typography>
                        <IconButton edge='start' color='inherit' onClick={() => { localStorage.removeItem('token-corferias'); localStorage.removeItem('auth-corferias'); localStorage.removeItem('username-corferias'); setAccessToken(''); setAuth(false); setUsername(''); }}>
                            <LogoutIcon />
                        </IconButton>
                    </React.Fragment>
                    :
                    null
                }
            </Toolbar>
        </AppBar>
    );

    useEffect(() => {
        return history.listen((location) => {
            setPathname(location.pathname)
        })
    }, [history]);

    useEffect(() => {
        document.title = "Visualización CNC - Corferias"
    }, []);

    return (
        <ThemeProvider theme={actualTheme}>
            <Redirect
                to={{
                    pathname: !auth ?
                        `/client/${clientUUID}/login`
                        :
                        pathsVisores.includes(pathname) ?
                            pathname :
                            `/client/${clientUUID}/home`,
                    state: { from: props.location }
                }}
            />
            <Route exact path={`/client/${clientUUID}/login`}>
                {!auth ?
                    <React.Fragment>
                        {appBarCustom}
                        <Container maxWidth="xs">
                            <Sesion theme={theme} errors={[sessionErrors, setSessionErrors]} validateSession={validateSession} />
                        </Container>
                    </React.Fragment>
                    :
                    null
                }
            </Route>
            <Route exact path={`/client/${clientUUID}/home`}>
                {auth ?
                    <React.Fragment>
                        {appBarCustom}
                        <Container maxWidth="lg">
                            <Grid container direction="row" alignItems="stretch" spacing={2}>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#b21a26", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Interzum</Typography>}
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor81Corferias} alt="visor81-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#b21a26'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor81UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#39bce5", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Createx</Typography>}
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor80Corferias} alt="visor80-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#39bce5'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor80UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#f6b911", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Filbo 2024</Typography>}
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor79Corferias} alt="visor79-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#f6b911'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor79UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#f6b911", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>ExpoArtesanias Especializados</Typography>}
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor78Corferias} alt="visor78-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#f6b911'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor78UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#f6b911", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>ExpoArtesanias Expositores</Typography>}
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor77Corferias} alt="visor77-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#f6b911'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor77UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#431943", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Comic Con Medellín Expositores</Typography>}
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor76Corferias} alt="visor76-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#431943'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor76UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#b13348", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>BiciGO Masivos</Typography>}
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor75Corferias} alt="visor75-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#b13348'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor75UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#b13348", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>BiciGO Especializados</Typography>}
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor74Corferias} alt="visor74-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#b13348'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor74UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#b13348", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>BiciGO Expositores</Typography>}
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor73Corferias} alt="visor73-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#b13348'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor73UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#39324b", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>SilverExpo Cali Visitantes</Typography>}
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor72Corferias} alt="visor72-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#39324b'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor72UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#39324b", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>SilverExpo Cali Expositores</Typography>}
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor71Corferias} alt="visor71-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#39324b'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor71UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#f6b4bb", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Chocoshow Visitantes</Typography>}
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor70Corferias} alt="visor70-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#f6b4bb'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor70UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#f6b4bb", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Chocoshow Expositores</Typography>}
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor69Corferias} alt="visor69-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#f6b4bb'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor69UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#4a87c6", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Bahari Masivos</Typography>}
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor68Corferias} alt="visor68-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#4a87c6'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor68UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#4a87c6", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Bahari Especializados</Typography>}
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor67Corferias} alt="visor67-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#4a87c6'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor67UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#4a87c6", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Bahari Expositores</Typography>}
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor66Corferias} alt="visor66-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#4a87c6'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor66UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#70b533", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>AgroFuturo Visitantes</Typography>}
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor65Corferias} alt="visor65-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#70b533'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor65UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#70b533", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>AgroFuturo Expositores</Typography>}
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor64Corferias} alt="visor64-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#70b533'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor64UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#3d3e52", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>SOFA Visitantes</Typography>}
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor63Corferias} alt="visor63-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#3d3e52'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor63UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#3d3e52", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>SOFA Expositores</Typography>}
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor62Corferias} alt="visor62-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#3d3e52'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor62UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#f8ba1a", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Sabor Barranquilla Visitantes</Typography>}
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor61Corferias} alt="visor61-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#f8ba1a'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor61UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#f8ba1a", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Sabor Barranquilla Expositores</Typography>}
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor60Corferias} alt="visor60-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#f8ba1a'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor60UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#5db7e8", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Gran Salón Inmobiliario Visitantes </Typography>}
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor59Corferias} alt="visor59-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#5db7e8'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor59UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#bc8052", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Construferia Visitantes </Typography>}
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor57Corferias} alt="visor57-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#bc8052'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor57UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#bc8052", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Construferia Expositores </Typography>}
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor56Corferias} alt="visor56-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#bc8052'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor56UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#CF152D", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Consolidado Ferias</Typography>}
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor55Corferias} alt="visor55-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#CF152D'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor55UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#39324b", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>SilverExpo Expositores Barranquilla</Typography>}
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor54Corferias} alt="visor52-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#39324b'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor54UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#39324b", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>SilverExpo Masivos Barranquilla</Typography>}
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor53Corferias} alt="visor53-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#39324b'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor53UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#1460ab", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>ExpoPet Masivos</Typography>}
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor52Corferias} alt="visor52-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#1460ab'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor52UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#1460ab", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>ExpoPet Expositores</Typography>}
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor51Corferias} alt="visor51-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#1460ab'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor51UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#1460ab", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>ExpoPet Especializados</Typography>}
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor50Corferias} alt="visor50-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#1460ab'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor50UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#f1d5d1", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Estilo Caribe Especializados</Typography>}
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor49Corferias} alt="visor49-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#f1d5d1'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor49UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#f1d5d1", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Estilo Caribe Masivos</Typography>}
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor48Corferias} alt="visor48-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#f1d5d1'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor48UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#f1d5d1", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Estilo Caribe Expositores</Typography>}
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor47Corferias} alt="visor47-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#f1d5d1'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor47UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#d95284", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Belleza y Salud Expositores</Typography>}
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor46Corferias} alt="visor46-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#d95284'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor46UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#d95284", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Belleza y Salud Masivos</Typography>}
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor45Corferias} alt="visor45-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#d95284'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor45UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#d95284", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Belleza y Salud Especializados</Typography>}
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor44Corferias} alt="visor44-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#d95284'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor44UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#6a3053", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Cafés Colombia Especializados</Typography>}
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor43Corferias} alt="visor43-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#6a3053'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor43UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#6a3053", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Cafés Colombia Masivos</Typography>}
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor42Corferias} alt="visor42-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#6a3053'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor42UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#6a3053", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Cafés Colombia Expositores</Typography>}
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor41Corferias} alt="visor41-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#6a3053'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor41UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#138085", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Colombiaplast Visitantes</Typography>}
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor40Corferias} alt="visor40-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#138085'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor40UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#138085", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Colombiaplast Expositores</Typography>}
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor39Corferias} alt="visor39-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#138085'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor39UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#0d0f20", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>ExpoDrinks Visitantes</Typography>}
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor38Corferias} alt="visor38-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#0d0f20'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor38UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#0d0f20", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>ExpoDrinks Expositores</Typography>}
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor37Corferias} alt="visor37-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#0d0f20'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor37UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#ea5723", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Market Fest Vintage Visitantes</Typography>}
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor36Corferias} alt="visor36-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#ea5723'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor36UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#ea5723", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Market Fest Vintage Expositores</Typography>}
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor35Corferias} alt="visor35-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#ea5723'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor35UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#115679", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Feria Internacional de Seguridad Visitantes</Typography>
                                            }
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor34Corferias} alt="visor34-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#115679'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor34UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#115679", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Feria Internacional de Seguridad Expositores</Typography>
                                            }
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor33Corferias} alt="visor33-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#115679'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor33UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#10b2a7", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Edutechnia Visitantes</Typography>
                                            }
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor32Corferias} alt="visor32-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#10b2a7'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor32UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#10b2a7", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Edutechnia Expositores</Typography>
                                            }
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor31Corferias} alt="visor31-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#10b2a7'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor31UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#74bae8", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Proyectate Barranquilla Visitantes</Typography>
                                            }
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor30Corferias} alt="visor30-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#74bae8'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor30UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#74bae8", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Proyectate Barranquilla Expositores</Typography>
                                            }
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor29Corferias} alt="visor29-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#74bae8'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor29UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#65257f", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Feria del Hogar Visitantes</Typography>
                                            }
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor28Corferias} alt="visor28-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#65257f'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor28UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#65257f", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Feria del Hogar Expositores</Typography>
                                            }
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor27Corferias} alt="visor27-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#65257f'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor27UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#39324b", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>SilverExpo Visitantes</Typography>
                                            }
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor26Corferias} alt="visor26-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#39324b'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor26UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#8fd0da", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Gran salón inmobiliario Expositores</Typography>
                                            }
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor25Corferias} alt="visor25-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#8fd0da'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor25UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#39324b", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>SilverExpo Expositores</Typography>
                                            }
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor24Corferias} alt="visor24-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#39324b'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor24UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#3e337b", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Createx Visitantes</Typography>
                                            }
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor23Corferias} alt="visor23-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#3e337b'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor23UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#3e337b", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Createx Expositores</Typography>
                                            }
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor22Corferias} alt="visor22-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#3e337b'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor22UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#0d3558", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>ExpoBIC Visitantes</Typography>
                                            }
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor21Corferias} alt="visor21-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#0d3558'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor21UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#0d3558", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>ExpoBIC Expositores</Typography>
                                            }
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor20Corferias} alt="visor20-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#0d3558'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor20UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#5db7e8", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Odontotech Visitantes</Typography>
                                            }
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor19Corferias} alt="visor19-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#5db7e8'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor19UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#5db7e8", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Meditech Expositores</Typography>
                                            }
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor58Corferias} alt="visor58-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#5db7e8'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor58UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#5db7e8", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Meditech Visitantes</Typography>
                                            }
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor18Corferias} alt="visor18-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#5db7e8'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor18UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#ee9d0f", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Agroexpo Visitantes</Typography>
                                            }
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor17Corferias} alt="visor17-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#ee9d0f'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor17UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#ee9d0f", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Agroexpo Expositores</Typography>
                                            }
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor16Corferias} alt="visor16-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#ee9d0f'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor16UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#972f2f", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Nextcar Visitantes</Typography>
                                            }
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor15Corferias} alt="visor15-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#972f2f'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor15UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#972f2f", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Nextcar Expositores</Typography>
                                            }
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor14Corferias} alt="visor14-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#972f2f'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor14UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#ee9d0f", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Visitantes Especializados</Typography>
                                            }
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor13Corferias} alt="visor13-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#ee9d0f'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor13UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#5db7e8", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Odontotech Expositores</Typography>
                                            }
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor12Corferias} alt="visor12-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#5db7e8'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor12UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#131a2f", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Comic Con Visitantes</Typography>
                                            }
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor11Corferias} alt="visor11-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#131a2f'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor11UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#131a2f", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Comic Con Expositores</Typography>
                                            }
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor10Corferias} alt="visor10-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#131a2f'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor10UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#e045a7", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Vimo Visitantes</Typography>
                                            }
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor9Corferias} alt="visor9-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#e045a7'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor9UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#e045a7", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Vimo Expositores</Typography>
                                            }
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor8Corferias} alt="visor8-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#e045a7'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor8UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#d65b1f", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Alimentec Visitantes</Typography>
                                            }
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor7Corferias} alt="visor7-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#d65b1f'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor7UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#d65b1f", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Alimentec</Typography>
                                            }
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor6Corferias} alt="visor6-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#d65b1f'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor6UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#e21d29", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Interzum Bogotá</Typography>
                                            }
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor5Corferias} alt="visor5-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#e21d29'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor5UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#092f43", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Feria Internacional del Libro de Bogotá</Typography>
                                            }
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor4Corferias} alt="visor4-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#092f43'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor4UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#CF152D", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "white", fontSize: 21 }}>Resultados de las Ferias</Typography>
                                            }
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor3Corferias} alt="visor3-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#CF152D'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor3UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "linear-gradient(to right, #274759, #3D5C74)", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "white", fontSize: 21 }}>SOFA</Typography>
                                            }
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor1Corferias} alt="visor1-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#3D5C74'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor1UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardHeader
                                            style={{ background: "#333333", paddingTop: 12, paddingBottom: 12 }}
                                            title={
                                                <Typography variant="h6" style={{ color: "#FFFFFF", fontSize: 21 }}>Gran salón inmobiliario</Typography>
                                            }
                                        />
                                        <Divider />
                                        <CardContent style={{ padding: 8, display: "contents" }}>
                                            <img src={ImgVisor2Corferias} alt="visor2-corferias" />
                                        </CardContent>
                                        <CardActions style={{ justifyContent: "flex-end", paddingBottom: 12, paddingRight: 12 }}>
                                            <ThemeProvider theme={responsiveFontSizes(buttonColor('#333333'))}>
                                                <Button component={Link} variant="contained" size="small" to={`visual/${Visor2UUID}`}>
                                                    Ir
                                                </Button>
                                            </ThemeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Container>
                    </React.Fragment>
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[0]}>
                {auth ?
                    <Visor1Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[1]}>
                {auth ?
                    <Visor2Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[2]}>
                {auth ?
                    <Visor3Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[3]}>
                {auth ?
                    <Visor4Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[4]}>
                {auth ?
                    <Visor5Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[5]}>
                {auth ?
                    <Visor6Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[6]}>
                {auth ?
                    <Visor7Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[7]}>
                {auth ?
                    <Visor8Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[8]}>
                {auth ?
                    <Visor9Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[9]}>
                {auth ?
                    <Visor10Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[10]}>
                {auth ?
                    <Visor11Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[11]}>
                {auth ?
                    <Visor12Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[12]}>
                {auth ?
                    <Visor13Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[13]}>
                {auth ?
                    <Visor14Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[14]}>
                {auth ?
                    <Visor15Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[15]}>
                {auth ?
                    <Visor16Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[16]}>
                {auth ?
                    <Visor17Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[17]}>
                {auth ?
                    <Visor18Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[18]}>
                {auth ?
                    <Visor19Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[19]}>
                {auth ?
                    <Visor20Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[20]}>
                {auth ?
                    <Visor21Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[21]}>
                {auth ?
                    <Visor22Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[22]}>
                {auth ?
                    <Visor23Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[23]}>
                {auth ?
                    <Visor24Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[24]}>
                {auth ?
                    <Visor25Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[25]}>
                {auth ?
                    <Visor26Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[26]}>
                {auth ?
                    <Visor27Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[27]}>
                {auth ?
                    <Visor28Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[28]}>
                {auth ?
                    <Visor29Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[29]}>
                {auth ?
                    <Visor30Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[30]}>
                {auth ?
                    <Visor31Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[31]}>
                {auth ?
                    <Visor32Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[32]}>
                {auth ?
                    <Visor33Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[33]}>
                {auth ?
                    <Visor34Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[34]}>
                {auth ?
                    <Visor35Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[35]}>
                {auth ?
                    <Visor36Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[36]}>
                {auth ?
                    <Visor37Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[37]}>
                {auth ?
                    <Visor38Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[38]}>
                {auth ?
                    <Visor39Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[39]}>
                {auth ?
                    <Visor40Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[40]}>
                {auth ?
                    <Visor41Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[41]}>
                {auth ?
                    <Visor42Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[42]}>
                {auth ?
                    <Visor43Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[43]}>
                {auth ?
                    <Visor44Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[44]}>
                {auth ?
                    <Visor45Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[45]}>
                {auth ?
                    <Visor46Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[46]}>
                {auth ?
                    <Visor47Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[47]}>
                {auth ?
                    <Visor48Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[48]}>
                {auth ?
                    <Visor49Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[49]}>
                {auth ?
                    <Visor50Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[50]}>
                {auth ?
                    <Visor51Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[51]}>
                {auth ?
                    <Visor52Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[52]}>
                {auth ?
                    <Visor53Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[53]}>
                {auth ?
                    <Visor54Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[54]}>
                {auth ?
                    <Visor55Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[55]}>
                {auth ?
                    <Visor56Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[56]}>
                {auth ?
                    <Visor57Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[57]}>
                {auth ?
                    <Visor58Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[58]}>
                {auth ?
                    <Visor59Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[59]}>
                {auth ?
                    <Visor60Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[60]}>
                {auth ?
                    <Visor61Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[61]}>
                {auth ?
                    <Visor62Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[62]}>
                {auth ?
                    <Visor63Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[63]}>
                {auth ?
                    <Visor64Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[64]}>
                {auth ?
                    <Visor65Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[65]}>
                {auth ?
                    <Visor66Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[66]}>
                {auth ?
                    <Visor67Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[67]}>
                {auth ?
                    <Visor68Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[68]}>
                {auth ?
                    <Visor69Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[69]}>
                {auth ?
                    <Visor70Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[70]}>
                {auth ?
                    <Visor71Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[71]}>
                {auth ?
                    <Visor72Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[72]}>
                {auth ?
                    <Visor73Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[73]}>
                {auth ?
                    <Visor74Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[74]}>
                {auth ?
                    <Visor75Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[75]}>
                {auth ?
                    <Visor76Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[76]}>
                {auth ?
                    <Visor77Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[77]}>
                {auth ?
                    <Visor78Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[78]}>
                {auth ?
                    <Visor79Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[79]}>
                {auth ?
                    <Visor80Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
            <Route exact path={pathsVisores[80]}>
                {auth ?
                    <Visor81Corferias
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        username={[username, setUsername]}
                        setAuth={setAuth}
                        clientUUID={clientUUID}
                        setMLCopyright={setMLCopyright}
                    />
                    :
                    <Redirect to={`/client/${clientUUID}/login`} />
                }
            </Route>
        </ThemeProvider>
    );
}